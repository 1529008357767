import {createApp} from 'vue'
import router from './router'
import store from './store'
import messages from './lang';
import {createI18n} from 'vue-i18n'
import App from './App.vue'
import dayjs from 'dayjs'
var localizedFormat = require('dayjs/plugin/localizedFormat')
var customParseFormat = require('dayjs/plugin/customParseFormat')
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
    weekdays: [
        "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"
    ]
})

dayjs.updateLocale('de', {
    weekdays: [
        "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"
    ]
})



window.axios = require('axios');
window.axios.defaults.baseURL = process.env.VUE_APP_APIROOT;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
/*
const i18n = new VueI18n({
    locale: 'de',
    silentTranslationWarn: true,
    fallbackLocale: 'de',
    messages
});*/

const i18n = createI18n({
    locale: 'de',
    silentTranslationWarn: true,
    allowComposition: false, // you need to specify that!
    messages
})
var app = createApp({
    extends: App
});

app.config.globalProperties.$dayjs = dayjs

app.use(dayjs);
app.use(i18n);
app.use(router);
app.use(store);


router.isReady().then(() => {

    app.mount('#app')

});
