export default {
    namespaced: true,
    state: () => ({
        cart: {
            cart: {
                comment: '',
                delivery_method: null,
                deliver_target: null,
                delivery_data: {
                    pickup_location: null,
                    driving_duration: null,

                },
                payment_method: null,
                payment_data: {

                },
                order_total: 0
            }
        },
        cart_items: [],
        view: {
            mobile: false
        }
    }),
    getters: {
      totalCartItems(state){
          var total = 0;
          state.cart_items.forEach((item) => {
              total += parseFloat(item.quantity);
          })
          return Math.round(total);
      }
    },
    actions: {
        addCartItem({state, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('cart_items', payload)
                    .then((response) => {
                        dispatch('fetchCartItems');
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        removeCartItem({state, dispatch}, payload){
            return new Promise((resolve, reject) => {
                axios.delete('cart_items/' + payload.id)
                    .then((response) => {
                        dispatch('fetchCartItems');
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        order({state,dispatch}){
            return new Promise((resolve, reject) => {
                axios.post('order')
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        updateCartItem({state, dispatch}, payload){
            return new Promise((resolve, reject) => {
                axios.patch('cart_items/' + payload.id, payload.values)
                    .then((response) => {
                        dispatch('fetchCartItems');
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        fetchCartItems({state, dispatch}) {
            return new Promise((resolve, reject) => {
                axios.get('cart_items')
                    .then((response) => {
                        state.cart_items = response.data;
                        dispatch('fetchCart');
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        fetchCart({state}) {
            return new Promise((resolve, reject) => {
                axios.get('cart')
                    .then((response) => {
                        state.cart = response.data;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        updateCart({state, dispatch}) {
            return new Promise((resolve, reject) => {
                axios.patch('customer', { cart: state.cart.cart })
                    .then((response) => {
                        state.cart = response.data;
                        dispatch('fetchCart');
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        },
        fetchCategories({state}) {
            axios.get('menu_categories?relations=["menu_items"]')
                .then((response) => {
                    state.items = response.data;
                });
        }
    }
}