export default {
    namespaced: true,
    state: () => ({
        items: [],
        expanded: false
    }),
    getters: {
      selectedDeliveryMethod(state, getters, rootState) {
          var dm = rootState.cart.cart.cart.delivery_method;

          var data = state.items.find((item) => {
              if (item.id === dm) {
                  return item;
              }
          });

          return data;
      }
    },
    actions: {
        fetchDeliveryMethods({state, dispatch}) {
            return new Promise((resolve, reject) => {
                axios.get('delivery_methods?relations=["delivery_type","payment_methods"]')
                    .then((response) => {
                        state.items = response.data;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        }
    }
}