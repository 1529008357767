export default {
    namespaced: true,
    state: () => ({
        license: {
            title: '',
            country: 'CH',
            currency: 'CHF',
            currency_rounding: 0.05,
            timezone: 'Europe/Zurich'
        }
    }),
    actions: {
        load({state}){
            return new Promise((resolve, reject) => {
               axios.get('license')
                   .then((response) => {
                       state.license = response.data;
                        resolve();
                   })
            });
        }
    }
}