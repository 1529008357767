export default {
    namespaced: true,
    state: () => ({
        customer: null,
        token: null,
        initialized: false
    }),
    mutations: {
        setUser(state, payload) {
            state.customer = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setUsername(state, payload) {
            state.customer.name = payload;
        },
        setEmail(state, payload) {
            state.customer.email = payload;
        }
    },
    getters: {
        loggedIn(state) {
            return (state.customer !== null && state.token !== null);
        },
        currentUser(state) {
            return state.customer;
        }
    },
    actions: {
        restoreLocalStorage({state, dispatch}) {
            return new Promise((resolve, reject) => {

                var token = localStorage.getItem('token');

                if (token === null) {
                    dispatch('initialize');
                    // state.initialized = true;
                    reject();
                    return false;
                }
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
                state.token = token;
                state.customer = {};
                dispatch('persistCredentials');
                dispatch('checkLogin')





                    .then(() => {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
                        dispatch('cart/fetchCart', {}, { root: true});
                        dispatch('cart/fetchCartItems', {}, { root: true});
                        dispatch('deliverymethods/fetchDeliveryMethods', {}, { root: true});
                        dispatch('paymentmethods/fetchPaymentMethods', {}, { root: true});
                        resolve();
                    })
                    .catch(() => {

                       dispatch('initialize')
                            .then(() => {
                                resolve();
                            }).catch((error) => {

                            reject();
                        })
                    });

                return true;
            })
        },
        persistCredentials({state}) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
            localStorage.setItem('token', state.token);
        },
        verifyAccount(_, payload) {
            return new Promise((resolve, reject) => {
                axios.patch('/users/verify', {token: payload.token})
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            });
        },
        register({commit, dispatch, state}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/users', {
                    name: payload.name,
                    email: payload.email,
                    password: payload.password
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                })
            });
        },
        initialize({commit, dispatch, state}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/customer/initialize', {
                }).then((response) => {
                    state.token = response.data.token;
                   // state.customer = response.data;
                   // commit('setUsername', response.data.user.name);
                  //  commit('setEmail', response.data.user.email);
                    dispatch('persistCredentials');


                    dispatch('cart/fetchCart', {}, { root: true});
                    dispatch('cart/fetchCartItems', {}, { root: true});
                    dispatch('deliverymethods/fetchDeliveryMethods', {}, { root: true});
                    dispatch('paymentmethods/fetchPaymentMethods', {}, { root: true});

                    state.initialized = true;

                    resolve(response.data);
                }).catch((error) => {
                    state.initialized = false;
                    reject(error);
                })
            });
        },
        login({commit, dispatch, state}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/users/login', {
                    email: payload.email,
                    password: payload.password
                }).then((response) => {
                    state.token = response.data.token;
                    state.initialized = true;
                    state.customer = {};
                    commit('setUsername', response.data.user.name);
                    commit('setEmail', response.data.user.email);
                    dispatch('persistCredentials');
                    resolve(response.data);
                }).catch((error) => {
                    state.initialized = true;
                    reject(error);
                })
            });
        },
        logout({commit}) {
            return new Promise((resolve) => {
                window.axios.defaults.headers.common = {};
                commit('setToken', null);
                localStorage.removeItem('token');
                resolve();
            });
        },
        checkLogin({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {

                if (state.token === null) {
                    dispatch('logout');
                    reject();
                    return;
                }

                axios.get('/customer')
                    .then((response) => {
                        commit('setToken', state.token);
                       // commit('setUsername', response.data.name);
                       // commit('setEmail', response.data.email);
                        dispatch('persistCredentials');
                        state.initialized = true;
                        resolve();
                    })
                    .catch((error) => {
                        state.initialized = true;
                        reject(error);
                    });
            });
        }
    }
}
