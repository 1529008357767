import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/',
        component: () => import('@/views/frames/Proxy.vue'),
        children: [
            {
                path: '/',
                component: () => import('@/views/frames/Store.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import('@/components/MenuListing.vue'),
                        children: [

                        ]
                    }, {
                        path: '/products/:id',
                        component: () => import('@/components/Product.vue'),
                        children: [

                        ]
                    }
                ]
            },
            {
                path: '/terms',
                component: () => import('@/views/store/TermsAndConditions'),
                children: [

                ]
            },
            {
                path: '/order/:tracking_id',
                component: () => import('@/views/store/OrderTracking.vue'),
                children: [

                ]
            },
            {
                path: '/order/:tracking_id/payment',
                component: () => import('@/views/store/OrderPayment.vue'),
                children: [

                ]
            }

        ]
    },
    //  {path: "/:pathMatch(.*)*", component: () => import('@/components/NotFound.vue'),}

];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});


export default router;
