export default {
    namespaced: true,
    state: () => ({
        items: [],
        expanded: false
    }),
    actions: {
        fetchPaymentMethods({state, dispatch}) {
            return new Promise((resolve, reject) => {
                axios.get('payment_methods')
                    .then((response) => {
                        state.items = response.data;
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        }
    }
}