export default {
    namespaced: true,
    state: () => ({
        expanded: false
    }),
    getters: {
      selectedDeliveryMethod(state, getters, rootState) {
          var dm = rootState.cart.cart.cart.delivery_method;

          var data = state.items.find((item) => {
              if (item.id === dm) {
                  return item;
              }
          });

          return data;
      }
    },
    actions: {
        orderByTrackingId({state}, payload) {
            return new Promise((resolve, reject) => {
                axios.get('orders/tracking/' + payload.tracking_id)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            })
        }
    }
}