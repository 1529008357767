export default {
    namespaced: true,
    state: () => ({
        items: [],
        categories: [],
        search: '',
        product: {},
        productOptions: {
            options: []
        }
    }),
    actions: {
        fetchProduct({state}, payload) {
            state.productOptions.options.splice(0);
            return new Promise((resolve, reject) => {
                axios.get('menu_items/' + payload.id + '?relations=["media", "options"]&filters=' + JSON.stringify([{ property: 'public', operator: '=', value: true}])).then((response) => {
                    state.product = response.data;
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        fetchItems({state}) {
            axios.get('menu_items?filters=' + JSON.stringify([{ property: 'public', operator: '=', value: true}]))
                .then((response) => {
                    state.items = response.data;
                });
        },
        fetchCategories({state}) {
            axios.get('menu_categories?relations=["menu_items"]&filters=' + JSON.stringify([{ property: 'public', operator: '=', value: true}]))
                .then((response) => {
                    state.items = response.data;
                });
        }
    }
}