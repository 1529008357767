import {createStore} from 'vuex'
import license from "@/store/modules/license";
import customer from "@/store/modules/customer";
import menu from "@/store/modules/menu";
import cart from "@/store/modules/cart";
import deliverymethods from "@/store/modules/deliverymethods";
import orders from "@/store/modules/orders";
import paymentmethods from "@/store/modules/paymentmethods";

const store = createStore({
    modules: {
        license,
        customer,
        menu,
        cart,
        deliverymethods,
        orders,
        paymentmethods
    }
});

export default store;
