<template>
  <router-view></router-view>
</template>
<script>
export default {
  data: function(){
    return {
      intervals: {
        deliveryMethods: null
      }
    }
  },
  mounted: function () {
    this.$store.dispatch('license/load')
        .then((response) => {
          document.title = this.$store.state.license.license.title + ' - Powered by Payfish';
        });
    this.$store.dispatch('customer/restoreLocalStorage');
    this.deliveryMethods = setInterval(() => {
      this.$store.dispatch('deliverymethods/fetchDeliveryMethods');
    }, 120000);

  }
}
</script>
<style lang="scss">
@import '@/sass/app.scss';
</style>
